import { wildResponseHandler } from '@wildidea/tools/utils/wildResponseHandler.js'

import { IUserDataService } from '../src/domain/IUserDataService.js'

import {
  GetUserMessagesInput,
  GetUserMessagesResponse,
  TrackingProviderId,
  API_PREFIX,
  ITrackingProviderId,
  GetUserTracksInput,
  GetUserTracksResponse,
  UserStatus,
  GetTilesetInput
} from './model.js'

export class VectorTileServiceClient {
  getTilesetEndpoint(input: GetTilesetInput) {
    return `${API_PREFIX}/tilesets/${input.name}${input.name === 'user' ? `/${input.options.userId}` : ''}`
  }
}

export class UserDataServiceClient implements IUserDataService {
  protected baseHeaders: Record<string, string> = { Accept: 'application/json' }

  async getStatus(input: ITrackingProviderId): Promise<UserStatus> {
    return wildResponseHandler(
      window.fetch(`${API_PREFIX}/users/${new TrackingProviderId(input).toString()}/status`, {
        headers: this.baseHeaders
      }),
      `${UserDataServiceClient.name}.${this.getStatus.name}`
    )
  }

  async getMessages(input: GetUserMessagesInput): Promise<GetUserMessagesResponse> {
    const searchParams = new URLSearchParams(
      Object.entries({
        startDate: input.timeRange?.startDate?.toISOString(),
        endDate: input.timeRange?.endDate?.toISOString(),
        eventIds: input.eventIds?.map((tsid) => new TrackingProviderId(tsid).toString()).join(',')
      }).filter<[string, string]>((entry): entry is [string, string] => !!entry[1])
    )

    return wildResponseHandler(
      window.fetch(
        `${API_PREFIX}/users/${new TrackingProviderId(input.user).toString()}/messages${searchParams.size ? `?${searchParams}` : ''}`,
        { headers: this.baseHeaders }
      ),
      `${UserDataServiceClient.name}.${this.getMessages.name}`
    )
  }

  async getTracks(input: GetUserTracksInput): Promise<GetUserTracksResponse> {
    const searchParams = new URLSearchParams(
      Object.entries({
        startDate: input.timeRange?.startDate?.toISOString(),
        endDate: input.timeRange?.endDate?.toISOString(),
        trackIds: input.trackIds?.join(',')
      }).filter<[string, string]>((entry): entry is [string, string] => !!entry[1])
    )

    return wildResponseHandler(
      window.fetch(
        `${API_PREFIX}/users/${new TrackingProviderId(input.user).toString()}/tracks${searchParams.size ? `?${searchParams}` : ''}`,
        { headers: this.baseHeaders }
      ),
      `${UserDataServiceClient.name}.${this.getTracks.name}`
    )
  }
}

export * from './model.js'
